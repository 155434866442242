import { useState, useEffect } from 'react'
import { config } from '../../config'
import { UserInfos } from '../interfaces'

const enableLogging = process.env.STAGE !== 'prod'

const loadBraze = () => import('@braze/web-sdk')

const changeBrazeUser = (userInfos) => {
  const { userId, email_address, phone_number, requestTraceId, trackingConsented = false, ...otherInfos } =
    userInfos
  window.braze.automaticallyShowInAppMessages()
  window.braze.changeUser(userId)
  window.braze.openSession()

  if(!trackingConsented){
    setOptedOutBrazeUserAttr({email_address, phone_number})
    return
  }
  
  setOptedOutBrazeUserAttr({email_address, phone_number})
  window.braze.getUser().setFirstName(userInfos.first_name)
  window.braze.getUser().setLastName(userInfos.last_name)
  Object.entries(otherInfos).forEach(([k, v]) => {
    setBrazeUserAttributes(k, v)
  })
}

const setOptedOutBrazeUserAttr = (infos) => {
  const { email_address, phone_number} = infos
  window.braze.getUser().setEmail(email_address)
  window.braze.getUser().setPhoneNumber(phone_number)
}

const setBrazeUserAttributes = (k, v) =>
  window.braze.getUser().setCustomUserAttribute(k, v)

interface UseBraze {
  userId: string
  country: string
  pathname: string
  trackingConsented?: boolean
  userInfos: UserInfos
}

const useBraze = ({
  userId,
  country,
  pathname,
  trackingConsented = false,
  userInfos,
}: UseBraze) => {
  const [brazeInitialized, setBrazeInitialized] = useState<boolean>(false)
  const optOutInApp = config[country].braze?.optOutInApp ?? true

  useEffect(() => {
    if (
      pathname === '/' ||
      brazeInitialized ||
      (!trackingConsented && !optOutInApp)
    )
      return

    if (process.env.STAGE !== 'prod') console.log('[useBraze] Initializing SDK')
    setBrazeInitialized(true)
    const { key, host: baseUrl } = config[country].braze
    loadBraze().then((braze) => {
      window.braze = braze
      window.braze.initialize(key, {
        baseUrl,
        enableLogging,
        allowUserSuppliedJavascript: true,
      })
      changeBrazeUser({ userId, trackingConsented, ...userInfos })
    })
  }, [trackingConsented])

  useEffect(() => {
    if (
      typeof window !== undefined &&
      window.braze &&
      window.braze.getUser().getUserId() !== userId
    )
      changeBrazeUser({ userId, trackingConsented, ...userInfos })
  }, [userId])

  return brazeInitialized
}

export default useBraze
